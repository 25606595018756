import { render, staticRenderFns } from "./AlertCard.vue?vue&type=template&id=6278b808&"
import script from "./AlertCard.vue?vue&type=script&lang=ts&"
export * from "./AlertCard.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports